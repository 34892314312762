















































































import { Component, Vue } from 'vue-property-decorator'
import {
    apikefuLangLists,
    apikefuLangDel,
    apikefuLangAdd,
    apikefuLangEdit
} from '@/api/application/service'
import { RequestPaging } from '@/utils/util'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import SpeechEdit from '@/components/marketing/speech-edit.vue'

@Component({
    components: {
        LsDialog,
        LsPagination,
        SpeechEdit
    }
})
export default class SpeechLists extends Vue {
    $refs!: { speechEdit: any }
    // 表单数据
    speechItem = {}
    pager: RequestPaging = new RequestPaging()

    /** E Data **/

    /** S Methods **/

    // 获取列表数据
    getLists(page?: number): void {
        page && (this.pager.page = page)
        // 请求管理员列表
        this.pager.request({
            callback: apikefuLangLists
        })
    }

    // 删除
    handleDelete(id: number) {
        apikefuLangDel({ id }).then(() => {
            // 删除成功就请求新列表
            this.getLists()
        })
    }

    handleAdd() {
        this.speechItem = {
            title: '',
            content: '',
            sort: ''
        }
        this.$refs.speechEdit.openDialog()
    }
    handleEdit({ id, title, content, sort }: any) {
        this.speechItem = {
            id,
            title,
            content,
            sort
        }
        this.$refs.speechEdit.openDialog()
    }

    handleSave(value: any) {
        const api = value.id ? apikefuLangEdit(value) : apikefuLangAdd(value)
        api.then(() => {
            this.$refs.speechEdit.closeDialog()
            this.getLists(1)
        })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getLists()
    }

    /** E Life Cycle **/
}
